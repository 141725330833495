import { MAINTAIN_PERMISSION, WEB_ANALYTICS_CLUSTERGROUPL2 } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "filter-cluster" }, [_c("select-filter", { key: "countryId", attrs: { "data-source": _vm.countries, "source": "countryId", "source-label": _vm.parseCountry, "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } }), _c("select-filter", { key: "id", attrs: { "data-source": _vm.clusters, "source": "id", "source-label": "clusterName", "default-active-first-option": "" }, on: { "change": _vm.onClusterChange } }), _c("select-filter", { key: "category", attrs: { "source": "category", "default-active-first-option": "", "data-source": _vm.categories, "source-label": _vm.parseCategory }, on: { "change": _vm.onCategoryChange } })], 1);
};
var staticRenderFns$3 = [];
var FilterClusterGroupMappingL2_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".filter-cluster[data-v-589c9386]{display:flex;align-items:center}.filter-cluster .select-filter[data-v-589c9386]{min-width:147px;margin-right:8px}\n")();
const COUNTRY_NAME = {
  AU: "Australia",
  NZ: "New Zealand"
};
const COUNTRY_AU_CODE = 1;
const __vue2_script$3 = {
  name: "FilterClusterGroupMappingL2",
  inject: ["resourceProps"],
  data() {
    const [countryProps, clusterProps, categoryProps] = this.resourceProps;
    return {
      countryProps,
      clusterProps,
      categoryProps
    };
  },
  computed: {
    countries() {
      return this.countryProps.crud.getList().slice(0, 2).map((country) => ({
        ...country,
        countryName: COUNTRY_NAME[country.country]
      }));
    },
    clusters() {
      return [
        {
          id: 0,
          clusterName: "All Cluster"
        },
        ...this.clusterProps.crud.getList()
      ];
    },
    categories() {
      return this.categoryProps.crud.getList();
    }
  },
  created() {
    this.fetchList(this.countryProps);
    this.fetchList(this.clusterProps);
  },
  methods: {
    getQueryString(params) {
      let query = "";
      if (Object.keys(params).length === 0 || params.constructor !== Object) {
        return query;
      }
      Object.entries(params).forEach(([key, value]) => {
        query += value ? `${key}=${value}&` : "";
      });
      return query.slice(0, -1);
    },
    fetchList(resourceProps, params = {}) {
      const query = this.getQueryString(params);
      resourceProps.crud.deleteFilter("IsInactive");
      resourceProps.crud.fetchList(null, true, true, query, true);
    },
    parseCountry(option) {
      return `${option.country} - ${option.countryName}`;
    },
    parseCategory(option) {
      return option.category === "00" ? "All Category" : option.categoryDesc;
    },
    onCountryChange(country) {
      this.fetchList(this.categoryProps, {
        country: country.value === COUNTRY_AU_CODE ? "AU" : "NZ"
      });
      this.$emit("countryChange", country);
    },
    onClusterChange(cluster) {
      this.$emit("clusterChange", cluster);
    },
    onCategoryChange(category) {
      this.$emit("categoryChange", category);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "589c9386", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var FilterClusterGroupMappingL2$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "filter-cluster-group" }, [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": [
    "web-analytics.common.countries",
    "web-analytics.common.clusters",
    "web-analytics.common.dgp-categories"
  ], "resource-id-name": ["countryId", "id", "category"] } }, [_c("filter-cluster-group-mapping-l2", { on: { "countryChange": _vm.onCountryChange, "clusterChange": _vm.onClusterChange, "categoryChange": _vm.onCategoryChange } })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    FilterClusterGroupMappingL2: FilterClusterGroupMappingL2$1
  },
  data() {
    return {
      apiUrl: apiUrl$1
    };
  },
  methods: {
    onCountryChange(country) {
      this.$emit("countryChange", country);
    },
    onClusterChange(cluster) {
      this.$emit("clusterChange", cluster);
    },
    onCategoryChange(category) {
      this.$emit("categoryChange", category);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var FilterClusterGroupMappingL2 = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", { attrs: { "title": "Cluster Mapping L2 - BrandGroup, Category, SubCategory, AU/NZ PromotionGroup", "search-by": "ClusterName,CountryCode", "ph-search-by": "Search by keywords", "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddClusterClick } }, [_vm._v(" Add Cluster ")])];
  }, proxy: true }, { key: "customFilter", fn: function() {
    return [_c("filter-cluster-group-mapping-l2", { on: { "countryChange": _vm.onCountryChange, "clusterChange": _vm.onClusterChange, "categoryChange": _vm.onCategoryChange } })];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Move")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "swap", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "ClusterName", attrs: { "data-index": "clusterName", "title": "Cluster L2", "sorter": true } }), _c("text-field", { key: "CountryCode", attrs: { "data-index": "countryCode", "title": "Country", "parse": _vm.parseCountry, "sorter": true } }), _c("text-field", { key: "BrandGroup", attrs: { "data-index": "brandGroupDesc", "title": "BrandGroup", "sorter": true } }), _c("text-field", { key: "Category", attrs: { "data-index": "category", "title": "Category", "parse": _vm.parseCatetory, "sorter": true } }), _c("text-field", { key: "SubCategory", attrs: { "data-index": "subCategory", "title": "SubCategory", "parse": _vm.parseSubCatetory, "sorter": true } }), _c("text-field", { key: "PromotionGroup", attrs: { "data-index": "promotionGroup", "title": _vm.filter.country === 1 ? "AUPromotionGroup " : "NZPRomotionGroup ", "parse": _vm.parsePromotionGroup, "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var ListClusterGroupMappingL2_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".cluster-filter[data-v-68ad19ba]{min-width:147px}.move-request[data-v-68ad19ba]{font-weight:500;font-size:16px;line-height:24px;color:#272d35}\n")();
const __vue2_script$1 = {
  name: "ListClusterGroupMappingL2",
  components: {
    FilterClusterGroupMappingL2
  },
  inject: ["createRoute", "crud", "apiUrl", "can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      countSelectedOption: 0,
      filter: {
        country: 1,
        cluster: "",
        category: ""
      }
    };
  },
  created() {
    const { country, cluster, category } = this.filter;
    this.crud.setFilter("Country", { operator: "Eq", value: country });
    this.crud.setFilter("ClusterId", { operator: "Eq", value: cluster });
    this.crud.setFilter("Category", { operator: "Eq", value: category });
  },
  methods: {
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    },
    parseCountry(value, record) {
      if (record.countryCode === "AU") {
        return `${value} - Australia`;
      }
      return `${value} - New Zealand`;
    },
    parseCatetory(value, record) {
      if (!value)
        return "";
      return `${value} - ${record.categoryDesc}`;
    },
    parseSubCatetory(subCategory, record) {
      if (!subCategory)
        return "";
      return `${subCategory} - ${record.subCategoryDesc}`;
    },
    parsePromotionGroup(promotionGroup, record) {
      if (!promotionGroup)
        return "";
      return `${promotionGroup} - ${record.promotionGroupDesc}`;
    },
    onCountryChange(country) {
      this.filter.country = country.value;
      this.crud.setFilter("Country", country);
      if (this.countSelectedOption >= 3) {
        this.fetchList();
      }
      this.countSelectedOption++;
    },
    onClusterChange(cluster) {
      if (cluster.value == "0") {
        this.crud.deleteFilter("ClusterId");
      } else {
        this.crud.setFilter("ClusterId", cluster);
      }
      if (this.countSelectedOption >= 3) {
        this.fetchList();
      }
      this.countSelectedOption++;
    },
    onCategoryChange(category) {
      if (category.value == "00") {
        this.crud.deleteFilter("Category");
      } else {
        this.crud.setFilter("Category", category);
      }
      if (this.countSelectedOption >= 3) {
        this.fetchList();
      }
      this.countSelectedOption++;
    },
    onAddClusterClick() {
      this.$router.push(this.createRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "68ad19ba", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListClusterGroupMappingL2 = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-cluster-group-mappingL1" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.cluster-l2-trans", "api-url": _vm.apiUrl, "create-route": "/web-analytics/cluster-l2-trans/create", "edit-route": "/web-analytics/cluster-l2-trans/:id", "page": _vm.page } }, [_c("list-cluster-group-mapping-l2")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListClusterGroupMappingL2
  },
  data() {
    return {
      page: WEB_ANALYTICS_CLUSTERGROUPL2,
      apiUrl,
      itemsMenu: [
        {
          key: "clustermapping",
          title: "Cluster Mapping",
          path: ""
        },
        {
          key: "clustergroupmappinl2",
          title: "Cluster Mapping L2",
          path: "/web-analytics/cluster-l2-trans"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
